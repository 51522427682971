<template>
    <div class="enterpriseCertification">
        <div class="top-title">
            <img src="@/assets/back-icon.png" @click="goBack" />
            <span>企业实名认证</span>
        </div>
        <div class="top-steps">
            <div class="top-steps-item">
                <div class="top-steps-item-dian1">
                    <span class="dian"></span>
                </div>
            </div>
            <span class="span1"></span>
            <div class="top-steps-item">
                <div class="top-steps-item-dian2"></div>
            </div>
            <span class="span2"></span>
            <div class="top-steps-item">
                <div class="top-steps-item-dian2"></div>
            </div>
        </div>
        <div class="top-steps-txt">
            <span class="active">提交基本信息</span>
            <span class="active1">提交认证资料</span>
            <span class="active2">认证完成</span>
        </div>
        <div class="content">
            <div class="title">企业证件</div>
            <div class="round">
                <span class="before"></span>
                <span class="after"></span>
            </div>
            <div class="describe">
                <div class="describe-left">
                    <i class="rules">*</i>
                    <span>营业执照</span>
                </div>
                <div class="describe-right">
                    请上传清晰的营业执照原件,系统将自动识别证件信息。照片仅限jpeg、jpg、png等图片格式且大小不超过15M。仅支持无水印或“用于聚优企实名认证”水印字样的照片。
                </div>
            </div>
            <div class="upload">
                <div class="upload-item">
                    <van-uploader v-model="fileList" :max-count="1" :before-read="beforeRead" :after-read="readAfterOne" v-if="fileList.length == 0">
                        <img src="@/assets/upload-icon.png" />
                    </van-uploader>
                    <div style="width:100%;height:100%;position: relative;"  v-if="fileList.length > 0">
                        <img src="@/assets/refuse.png" style="position: absolute;width: 20px;height: 20px;right: -10px;top: -10px;" @click="del(1)">
                        <img :src="fileUrl" alt="" style="width:100%;height:100%;">
                    </div>
                </div>
                <div class="span-text">点击拍摄/上传营业执照</div>
            </div>
            <div class="tip" v-show="fileList.length > 0">
                <img style="" src="@/assets/tip-icon.png" />
                <span>以下信息自动识别,请仔细核对,如识别有误,请修正。</span>
            </div>
            <div class="form-content" v-show="fileList.length > 0">
                <van-cell-group>
                    <div class="form-tip"><i class="rules">*</i>企业名称</div>
                    <van-field v-model="formObj.companyName" placeholder="请输入企业名称" />
                    <div class="form-tip"><i class="rules">*</i>统一社会信用代码/工商注册号</div>
                    <van-field v-model="formObj.companyCode" placeholder="请输入统一社会信用代码/工商注册号" />
                    <div class="form-tip"><i class="rules">*</i>法定代表人姓名</div>
                    <van-field v-model="formObj.legalRepresentative" placeholder="请输入请输入法定代表人姓名" />
                </van-cell-group>
            </div>
            <div class="title">个人证件</div>
            <div class="radio">
                <van-radio-group v-model="formObj.personType" @change="personChange">
                    <van-radio name="1">我是法定代表人</van-radio>
                    <van-radio name="3">我不是法定代表人，我是企业经办人，已获企业授权</van-radio>
                </van-radio-group>
            </div>
            <div class="describe">
                <div class="describe-left">
                    <i class="rules">*</i>
                    <span>身份证</span>
                </div>
                <div class="describe-right">
                    请上传清晰的身份证原件,系统将自动识别证件信息。照片仅限jpeg、jpg、png等图片格式且大小不超过15M。仅支持无水印或“用于聚优企实名认证”水印字样的照片。
                </div>
            </div>
            <div class="upload">
                <div class="upload-item1">
                    <van-uploader v-model="fileList1" :before-read="beforeRead" :max-count="1" :after-read="readafterThree" v-if="fileList1.length == 0">
                        <img src="@/assets/upload-icon.png" />
                    </van-uploader>
                    <div style="width:100%;height:100%;position: relative;"  v-if="fileList1.length > 0">
                        <img src="@/assets/refuse.png" style="position: absolute;width: 20px;height: 20px;right: -10px;top: -10px;" @click="del(2)">
                        <img :src="fileUrl1" alt="" style="width:100%;height:100%;">
                    </div>
                </div>
                <div class="span-text">点击拍摄/上传人像面</div>
            </div>
            <div class="upload">
                <div class="upload-item2">
                    <van-uploader v-model="fileList2" :before-read="beforeRead" :max-count="1" :after-read="readafterTwo" v-if="fileList2.length == 0">
                        <img src="@/assets/upload-icon.png" />
                    </van-uploader>
                    <div style="width:100%;height:100%;position: relative;"  v-if="fileList2.length > 0">
                        <img src="@/assets/refuse.png" style="position: absolute;width: 20px;height: 20px;right: -10px;top: -10px;" @click="del(3)">
                        <img :src="fileUrl2" alt="" style="width:100%;height:100%;">
                    </div>
                </div>
                <div class="span-text">点击拍摄/上传国徽面</div>
            </div>
            <div class="tip" v-show="fileList1.length > 0">
                <img src="@/assets/tip-icon.png" />
                <span>以下信息自动识别,请仔细核对,如识别有误,请修正。</span>
            </div>
            <div class="form-content" v-if="formObj.personType == '1' && fileList1.length > 0">
                <van-cell-group>
                    <div class="form-tip"><i class="rules">*</i>法定代表人姓名</div>
                    <van-field v-model="formObj.personName" placeholder="请输入法定代表人姓名" />
                    <div class="form-tip"><i class="rules">*</i>法定代表人身份证号</div>
                    <van-field v-model="formObj.personNo" type="idcard" placeholder="请输入法定代表人身份证号" />
                </van-cell-group>
            </div>
            <div class="form-content" v-if="formObj.personType == '3' && fileList1.length > 0">
                <van-cell-group>
                    <div class="form-tip"><i class="rules">*</i>经办人姓名</div>
                    <van-field v-model="formObj.personName" placeholder="请输入经办人姓名" />
                    <div class="form-tip"><i class="rules">*</i>经办人身份证号</div>
                    <van-field v-model="formObj.personNo" type="idcard" placeholder="请输入经办人身份证号" />
                </van-cell-group>
            </div>
            <div class="footer-btn">
                <div class="footer-btn-submit" @click="submit">确认提交</div>
            </div>
        </div>
    </div>
</template>
<script>
import { oss } from '@/util/tool'
import { mapState } from 'vuex'
import { getCredentialsInfo, saveCompanyBaseInfo, getCompanyRealnameInfo, ossPreviewUrl } from '@/util/api'
export default {
    data() {
        return {
            fileList: [],
            fileUrl: '',
            fileList1: [],
            fileUrl1: '',
            fileList2: [],
            fileUrl2: '',
            formObj: {
                companyId: this.$route.query.companyId,
                companyName: '', //企业名称
                companyCode: '', //企业统一社会信用编码
                legalRepresentative: '', //法定代表人名称
                businessLicense: '', //营业执照文件编码
                personType: '1', //操作人类型
                personIdCardFront: '', //操作人身份证(国徽面)
                personIdCardBack: '', //操作人身份证(人像面)
                personName: '', //操作人姓名
                personNo: '', // 操作人身份证号
            },
        }
    },
    computed: {
        ...mapState(['linkCodeInfo','setResultInfo'])
    },
    created () {
        if (this.$route.query.companyId) {
            getCompanyRealnameInfo(this.$route.query.companyId).then(res =>{
                this.formObj.personType = res.personType + ''
                if(!res.personType){
                    this.formObj.personType = '1'
                }
            })
        }
    },
    mounted () {
        setTimeout(()=>{
            if (this.$route.query.companyId) {
                getCompanyRealnameInfo(this.$route.query.companyId).then(res =>{
                    this.formObj.companyId =  this.$route.query.companyId
                    this.formObj.companyName =  res.companyName || ''
                    this.formObj.companyCode =  res.companyCode || ''
                    this.formObj.legalRepresentative =  res.legalRepresentative || ''
                    this.formObj.businessLicense =  res.businessLicense || ''
                    this.formObj.personIdCardFront =  res.personIdCardFront || ''
                    this.formObj.personIdCardBack =  res.personIdCardBack || ''
                    this.formObj.personName =  res.personName || ''
                    this.formObj.personNo =  res.personNo || '' 
                    if(res.businessLicenseFile){
                        this.fileList.push(res.businessLicenseFile)
                        this.ossPreviewUrl(res.businessLicenseFile.fileUrl,'2')
                    }
                    if(res.personIdCardBackFile){
                        this.fileList1.push(res.personIdCardBackFile)
                        this.ossPreviewUrl(res.personIdCardBackFile.fileUrl,'1')
                    }
                    if(res.personIdCardFrontFile){
                        this.fileList2.push(res.personIdCardFrontFile)
                        this.ossPreviewUrl(res.personIdCardFrontFile.fileUrl,null)
                    }
                })
            }
        },2000)
    },
    methods: {
        beforeRead (file){
            const isImgType = file.type === "image/jpeg" || file.type === "image/png"
            const isLt15M = file.size / 1024 / 1024 < 15
            if (!isImgType) {
            this.$toast("上传图片只能是 JPG或PNG 格式!")
                return false;
            }
            if (!isLt15M) {
                this.$toast("上传图片大小不能超过 15MB!")
                return false;
            }
            return true;
        },
        readAfterOne(file) {
            console.log(file,'1224');
            this.oss(2, file.file)
        },
        async oss(type, val) {
            console.log(val,'124')
            let file = {}
            let obj = {}
            let fileType = ''
            await oss(val).then(({ size, object, host, mimeType }) => {
                console.log(mimeType,'mimeType');
                if(mimeType === 'image/jpeg'){
                    fileType = '.jpg'
                }else{
                    fileType = '.png'
                }
                console.log(object,'object');
                file = {
                    fileDisplayName: 'signImage', fileExt: fileType, fileSize: size, fileUrl: `${host}${object}`
                }
                let path = `${host}${object}`
                this.ossPreviewUrl(path,type)
                obj.fileInfo = file
                obj.fileType = type
            })
            if(!obj.fileType){
                this.formObj.personIdCardFront = obj.fileInfo.fileUrl
            } else {
                await this.getCredentialsInfo(obj)
            }
        },
        ossPreviewUrl (path,type) {
            console.log(type,'type');
            ossPreviewUrl({path}).then(res =>{
                if(type == 1){
                    this.fileUrl1 = res
                }else if(type == 2){
                    this.fileUrl = res
                }else {
                    this.fileUrl2 = res
                }
            })
        },
        readafterThree(file) {
            this.oss(1, file.file)
        },
        getCredentialsInfo(obj) {
            getCredentialsInfo({ osspath: obj.fileInfo.fileUrl, fileType: obj.fileType }).then(res => {
                if(obj.fileType == 1){
                    this.formObj.personName = res.personName
                    this.formObj.personNo = res.personNo
                    this.formObj.personIdCardBack = res.fileCode
                }
                if(obj.fileType == 2){
                    this.formObj.companyName = res.companyName
                    this.formObj.companyCode = res.companyCode
                    this.formObj.legalRepresentative = res.legalRepresentative
                    this.formObj.businessLicense = res.fileCode
                }
                if(!obj.fileType){
                   this.formObj.personIdCardFront = res.fileCode
                }
                console.log(this.formObj,'1123');
                this.$forceUpdate()
            })
        },
        readafterTwo(file) {
            this.oss(null, file.file)
        },
        personChange () {
            this.fileList1 = []
            this.fileList2 = []
            this.formObj.personName = ''
            this.formObj.personNo = ''
        },
        submit() {
            this.formObj.companyId = this.$route.query.companyId || this.setResultInfo.companyId
            if(this.fileList.length == 0){
                this.$toast.fail("请上传营业执照")
                return
            }
            if(!this.formObj.companyName){
                this.$toast.fail("企业名称不能为空");
                return
            }
            if(!this.formObj.companyCode){
                this.$toast.fail("企业统一社会信用编码不能为空")
                return
            }
            if(!this.formObj.legalRepresentative){
                this.$toast.fail("法定代表人姓名不能为空")
                return
            }
            if(this.fileList1.length == 0){
                this.$toast.fail("请上传身份证人像面")
                return
            }
            if(!this.formObj.personName){
                this.$toast.fail("操作人姓名不能为空")
                return
            }
            if(!this.formObj.personNo){
                this.$toast.fail("操作人身份证号不能为空")
                return
            }
            if(this.fileList2.length == 0){
                this.$toast.fail("请上传身份证国徽面")
                return
            }
            if(this.formObj.personType == 1 && this.formObj.personName != this.formObj.legalRepresentative){
                this.$toast.fail('法定代表人名称不一致')
                return
            }
            this.formObj.realNamePurpose = 1
            saveCompanyBaseInfo(this.formObj).then(res =>{
                console.log(res);
                this.$router.push({ path: '/phaseTwo' })
            })        
            this.$store.commit('setCertificationInfo', this.formObj)
        },
        del (val) {
            if(val == 1){
                this.fileList.splice(0,1)
            }
             if(val == 2){
                this.fileList1.splice(0,1)
            }
             if(val == 3){
                this.fileList2.splice(0,1)
            }
        },
        goBack () {
            this.$router.go(-1)
        }
    }
}
</script>
<style lang="less" scoped>
.enterpriseCertification {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 15px 30px;
    background: #0067E1;
    box-sizing: border-box;

    .top-title {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 17px;
        img{
            width: 10px;
            height: 17px;
            cursor: pointer;
        }
        span{
            display: inline-block;
            flex: 1;
            text-align: center;
        }
    }

    .top-steps {
        width: 100%;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;

        .span1 {
            height: 1px;
            width: 120px;
            background: #fff;
            margin: 0 5px;
        }

        .span2 {
            height: 1px;
            width: 120px;
            background: #6495ED;
            margin: 0 5px;
        }

        .top-steps-item {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .top-steps-item-dian1 {
                background: #6495ED;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .dian {
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background: #fff;
                    display: inline-block;
                }
            }

            .top-steps-item-dian2 {
                background: #6495ED;
                width: 10px;
                height: 10px;
                border-radius: 50%;
            }
        }

    }

    .top-steps-txt {
        width: 100%;
        display: flex;
        font-size: 12px;
        margin-bottom: 15px;
        span{
            display: inline-block;
        }

        .active {
            width: 30%;
            color: #fff;
            text-align: left;
        }

        .active1 {
            color: #fff;
            opacity: 0.5;
            width: 42%;
            text-align: center;
        }
        .active2 {
            color: #fff;
            opacity: 0.5;
            flex: 1;
            text-align: right;
            padding-right: 8px;
        }
    }

    .content {
        border-radius: 5px;
        background: #fff;
    }

    .title {
        width: 100%;
        height: 50px;
        text-align: center;
        font-size: 16px;
        color: #30586C;
        font-weight: 700;
        line-height: 70px;
    }
    .round {
        width: 100%;
        height: 10px;
        position: relative;

        .after {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            left: -5px;
            background: #0067E1;
        }

        .before {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            right: -5px;
            background: #0067E1;
        }
    }

    .rules {
        color: red;
        margin-right: 2px;
    }

    .describe {
        display: flex;
        font-size: 16px;
        padding: 0 15px;
        box-sizing: border-box;
        .describe-left {
            min-width: 70px;
            height: 30px;
            display: flex;
            color: #757B85;
            margin-right: 8px;
        }

        .describe-right {
            display: flex;
            flex-direction: column;
            text-align: left;
            flex: 1;
            color: #8D8D8D;
        }
    }

    .upload {
        width: 100%;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        flex-direction: column;
        font-size: 12px;
        .span-text {
            width: 100%;
            height: 10px;
            line-height: 15px;
            text-align: center;
            font-size: 14px;
            color: #333;
            margin: 10px 0;
        }
        .upload-item {
            width: 168px;
            height: 106px;
            display: flex;
            background: #f7f8fa;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            padding: 1px;

            img {
                width: 42px;
                height: 42px;
            }
            .van-uploader {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                /deep/.van-uploader__wrapper {
                    width: 100%;
                    height: 100%;

                    .van-uploader__input-wrapper {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                    }

                    .van-uploader__upload {
                        width: 100%;
                        height: 100%;
                        margin: 0;
                    }

                    .van-uploader__preview {
                        width: 100%;
                        height: 100%;
                        margin: 0;

                        .van-image {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }

        .upload-item1 {
            width: 168px;
            height: 106px;
            background-image: url("../../assets/shenfenzheng-bg1.png");
            background-size: 100% 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            padding: 1px;

            img {
                width: 42px;
                height: 42px;
            }

            span {
                width: 100%;
                height: 10px;
                line-height: 15px;
                text-align: center;
                font-size: 14px;
                color: #333;
                margin-top: 20px;
            }

            .van-uploader {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                /deep/.van-uploader__wrapper {
                    width: 100%;
                    height: 100%;

                    .van-uploader__input-wrapper {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                    }

                    .van-uploader__upload {
                        width: 100%;
                        height: 100%;
                        margin: 0;
                    }

                    .van-uploader__preview {
                        width: 100%;
                        height: 100%;
                        margin: 0;

                        .van-image {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }

        .upload-item2 {
            width: 168px;
            height: 106px;
            background-image: url('../../assets/shenfenzheng-bg2.png');
            background-size: 100% 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            padding: 1px;

            img {
                width: 42px;
                height: 42px;
            }

            span {
                width: 100%;
                height: 10px;
                line-height: 15px;
                text-align: center;
                font-size: 14px;
                color: #333;
                margin-top: 20px;
            }

            .van-uploader {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                /deep/.van-uploader__wrapper {
                    width: 100%;
                    height: 100%;

                    .van-uploader__input-wrapper {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                    }

                    .van-uploader__upload {
                        width: 100%;
                        height: 100%;
                        margin: 0;
                    }

                    .van-uploader__preview {
                        width: 100%;
                        height: 100%;
                        margin: 0;

                        .van-image {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    .tip {
        width: 100%;
        margin-top: 15px;
        display: flex;
        padding: 0 15px;
        align-items: center;
        word-wrap: break-word;
        display: flex;
        align-items: center;
        box-sizing: border-box;

        img {
            width: 10px;
            height: 10px;
        }

        span {
            padding: 0 10px;
            font-size: 12px;
            color: #DEBD5F;
            display: block;
        }
    }

    .form-content {
        width: 100%;
        box-sizing: border-box;
        padding: 0 15px;

        .form-tip {
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            color: #757B85;
        }

        /deep/.van-cell {
            margin: 0;
            font-size: 14px;
        }
        /deep/.van-field{
            background: #F6F7F8;
        }
    }

    .radio {
        width: 100%;
        margin-bottom: 10px;
        padding: 0 15px;
        box-sizing: border-box;

        /deep/.van-radio-group {
            font-size: 12px;

            .van-radio__icon {
                width: 10px;
                height: 10px;

                .van-icon {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .footer-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        margin-top: 20px;
        padding-bottom: 30px;

        .footer-btn-submit {
            width: 306px;
            height: 50px;
            background: linear-gradient(90deg, #0067E1, #77A3FF);
            border-radius: 25px;
            text-align: center;
            line-height: 50px;
            color: #fff;
        }
    }
}
</style>